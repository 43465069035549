.appl {
    &-form {
        &__title {
            font-weight: 600;
            font-family: "Montserrat", sans-serif;
            color: #222;
            font-size: 20px;
            line-height: 1.2;
            margin: 0 0 10px;
            padding: 0 0 12px;
        }

        &__subtitle {
            font-weight: 600;
            font-family: "Montserrat", sans-serif;
            color: #222;
            font-size: 15px;
            line-height: 1.2;
            margin: 0px 0 7px;
            padding: 0;
            flex-basis: 65%;

        }

        &__group {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-bottom: 20px;
        }

        &__row {
            flex-basis: 100%;
        }

        &__checkbox {
            input {
                display: none;

                &:checked~label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 6px;
                    left: 13px;
                    width: 7px;
                    height: 7px;
                    background: #000;
                }
            }

            label {
                position: relative;
                padding-left: 30px;
                font-size: 14px;
                line-height: 1.2;
                margin: 10px 0;
                color: #000;

                &:hover {
                    cursor: pointer;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 3px;
                    left: 10px;
                    width: 13px;
                    height: 13px;
                    border: 1px solid #000;
                }
            }
        }

        .form-group {
            display: flex;
            margin: 0;
            align-items: center;
            margin-bottom: 10px;

            @include respond-to(small) {
                flex-wrap: wrap;

            }

            label {
                flex-basis: 35%;
                text-align: right;
                max-width: 200px;
                color: #000;
                font-weight: bold;
                padding-right: 15px;
                font-size: 14px;
                flex-grow: 0;
                flex-shrink: 0;

                @include respond-to(small) {
                    width: 100%;
                    flex-basis: 100%;
                    max-width: 100%;
                    text-align: left;
                }

                &.error {
                    color: red;
                }
            }

            .form-control {
                border-radius: 5px;
                border-color: #333;
                height: 30px;
                font-size: 13px;
                color: #333;

                @include respond-to(small) {
                    width: 100%;
                    flex-basis: 100%;
                    max-width: 100%;
                    height: 40px;
                }
            }

            select.form-control {
                height: 30px;

                @include respond-to(small) {
                    height: 40px;
                }
            }
        }

        &__left {
            border-right: 1px solid #eee;
            padding-right: 40px;

            @include respond-to(1230) {
                padding-right: 15px;
                border-right: none;
                flex-basis: 100%;
                max-width: 700px;
                margin: 0 auto;
            }
        }

        &__right {
            padding-left: 40px;

            @include respond-to(1230) {
                padding-left: 15px;
                flex-basis: 100%;
                max-width: 700px;
                margin: 30px auto;
            }
        }

        &__sign {
            flex-wrap: wrap;

            &-reset {
                background: none;
                border: none;
                color: #fff;
                // text-decoration: underline;
                flex-basis: 100%;
                text-align: right;
                margin: 10px auto;
                max-width: 150px;
                ;
                background: #dd3d53;
                font-family: "Montserrat", sans-serif;
                font-size: 11px;
                line-height: 1.1;
                font-weight: 600;
                position: relative;
                padding: 4px 10px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 26px;
                cursor: pointer;
                box-shadow: 0 1px 4px rgba(0, 0, 0, .1);
                border-radius: 5px;
                transition: all .2s linear;

                &:hover {
                    text-decoration: none;
                }
            }

            &-img {
                height: 85px;
                border: 1px solid #000;
                padding: 10px;
                flex-basis: 65%;
                border-radius: 5px;

                @include respond-to(460) {
                    flex-basis: 100%;
                    height: 120px;
                }
            }
        }

        .g-contact_btn {
            margin-bottom: 50px;
        }

        &__success {
            z-index: 999;
            width: 700px;
            max-width: 98%;
            padding: 20px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(0%, -50%);
            opacity: 0;
            visibility: hidden;
            background: rgba(206, 33, 33, 0.9);
            // background: rgba(0, 0, 0,0.8);
            border: 1px solid #000;
            border-radius: 5px;
            text-align: center;
            transition: all 0.6s ease;

            &.is-show {
                transform: translate(-50%, -50%);
                opacity: 1;
                visibility: visible;
            }

            p {
                margin-bottom: 15px;
                color: #fff;
                font-weight: bold;

                a {
                    color: #fff;
                    text-decoration: underline;
                }

            }

        }
    }
}