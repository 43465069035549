@import "system/variables";
.list{
    text-align: left;
    margin: 30px auto 0;
    max-width: 620px;
    width: 100%;
     
    ul{
        padding-left: 20px;
        margin-top: 10px;
    }
    &-wrap{
        display: block; 
    }
    li{
        padding-left: 15px;
        padding-bottom: 7px;
        list-style: disc;
    }
}
.link{
    color: $brand;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}

.hero{
    &__imgs{
        position: relative;
        height: 100%;
        width: 100%;
    }
    &__logo{
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        width: 40%;
        position: absolute;
        img{
            width: 100%;
        }
    }
    &__img{
        position: absolute;
       bottom: 0;
       width: 60%;
       left: 50%;
       transform: translateX(-30%);
&:nth-child(2){
    transform: translateX(-50%);  
}
&:nth-child(3){
    transform: translateX(-70%);  
}
    }
}

.welcome{
    overflow: hidden;

    &__grid{
    position: relative;

    }
    &__descr{
        width: 55%;
        left: 0;
        margin: 0;
        // opacity: 0;
        // transform: translateY(50px);
        @include respond-to(small){
        width: 100%;
        font-size: 16px;
        }
        .list-wrap{
            opacity: 0;
            transform: translateY(50px); 
        }
        .tt-indent-btn{
            opacity: 0;
            // transform: translateY(50px); 

        }
        p{
            font-size: 20px;
            line-height: 1.4;
            @include respond-to(small){
                width: 100%;
                font-size: 16px;
                }
        }
    }
    &__img{
        width: 56%;
        position: absolute;
        // right: -20%;
        right: -100%;
        opacity: 0;
        top: -25%;
        @include respond-to(small){
        display: none;
        }
    }
}