@import "system/variables";
/** PARALLAX **/
.parallax {
    background-color: #000;
    position: relative;
    overflow: hidden;
    height: 200px;

    @include respond-to(small) {
        height: 300px;
    }

    &__bg {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 300%;
        top: -90%;
        z-index: 1;
        opacity: .7;
        @include respond-to(small){
            background-position: top center;
            height: 150%;
            top: -0%;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
    }

    &__content {
        position: relative;
        z-index: 1;

        &-wrap {
            width: 100%;
            height: 100%;

            .site_width {
                width: 100%;
                height: 100%;
            }
        }

        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &-el {
            flex-basis: 100%;
        }
    }

    &__title {
        color: #fff;
        text-align: center; 
        font-weight: 600;
        font-size: 40px;

        @include respond-to(small) {
            font-size: 28px;
        }

        span {
            position: relative;
            display: inline-block;

            &:before {
                content: '';
                display: block;
                background: #fff;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(-110%, -50%);
                 width: 90px;
              height: 4px;

                @include respond-to(small) {
                    display: none;
                }
            }

            &:after {
                content: '';
                display: block;
                background: #fff;
                position: absolute;
                right: 0%;
                top: 50%;
                transform: translate(110%, -50%);
                width: 90px;
                   height: 4px;

                @include respond-to(900) {
                    display: none;
                }
            }
        }
    }

}
